import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import programListStore from '@/store/modules/programList';
import programConfigureStore from '@/store/modules/programConfigure';
import APP_CONST from '@/constants/AppConst';
import tableStore from '@/store/modules/table';
import TableComponent from '@/commoncomponents/tablecomponent/TableComponent.vue';
import { AccountDetails, AccountDtById } from '@/Model/model.js';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import APP_UTILITIES from '@/utilities/commonFunctions';
import store from '@/store';
import { resetProgram } from '@/services/userService/users-api';
import { ProgramListDTO } from '@/services/program/adminService';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import { ToastType } from '@/Model/toastType';

@Component({
  components: {
    'program-table': TableComponent,
    'bouncing-preloader': BouncingPreloaderComponent
  }
})
export default class ProgramListComponent extends Vue {
  private objScreenText: ScreenText = new ScreenText();
  public program_header: any = APP_CONST.PROGRAMS_HEADER;
  public sort_list: any = APP_CONST.PROGRAM_SORT_LIST;
  public key: any = APP_CONST.PROGRAM_KEY;
  public actions: any = APP_CONST.PROGRAM_ACTION;
  public program_list: any = [];
  public total_items: number | null = null;
  public search: string = '';
  public accountDt: AccountDetails = {} as AccountDetails;
  public programModuleInstance: any = {};
  public accountId: number = 0;
  private accountAdminLength: number = 0;
  public showAcAdminDetails: boolean = false;
  private hover: boolean = false;
  private hoverText: string = '';
  private hoverVal: string = '';
  private hoverIndex: number = 0;
  public manageProgram = 'manageProgram';
  private authKey: string = '';
  private programPayload: any = APP_CONST.PROGRAM_PAYLOAD;
  public showLoader: boolean = false;
  public hoverStyleObj: any = '';
  getGlobalState = getModule(GlobalModule, store);

  @Prop()
  userDetails!: any;



  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  get programs() {
    return programListStore.programsdata;
  }

  get ProgramQuery() {
    return tableStore.programQuery;
  }

  async programList(event: { page: number; sortField: string; sortDir: number; type: string }) {
    this.accountId = this.getGlobalState.AccountId;
    const payload = APP_CONST.PROGRAM_PAYLOAD;
    /* istanbul ignore else */
    if (event.sortField == '') {
      for (const el of this.program_header) {
        /* istanbul ignore else */
        if (el.order > 0) {
          event.sortField = el.value;
          event.sortDir = el.order;
          break;
        }
      }
    }
    const storedAccountId: any = APP_UTILITIES.getCookie('accountId');
    payload.id = parseInt(storedAccountId);
    payload.page = event.page
      ? event.page
      : 1;
    payload.search = this.search;
    payload.sortField = event.sortField
      ? event.sortField
      : 'programName';
    payload.sortDir = event.sortDir
      ? event.sortDir
      : 1;
    await programListStore.getProgramList(payload);
    /* istanbul ignore else */
    if (programListStore.programsdata.status === APP_CONST.RESPONSE_200) {
      this.program_list = [...programListStore.programsdata.data.results];
      this.total_items = programListStore.programsdata.data.count;
    }
    else {
      this.emptyProgramList();
    }
    this.programPayload = JSON.parse(JSON.stringify(payload));
    this.setSortingDefault();
  }

  emptyProgramList() {
    this.program_list = [];
    this.total_items = 0;
  }

  navigateToAccountAdmin() {
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    const userData = JSON.parse(userRoles);
    userData[0].roleId = APP_CONST.ACCOUNT_ADMIN_ROLE_ID;
    userData[0].accountId = this.accountDt.accountId;
    APP_UTILITIES.setCookie('super_user_drilldown', 'true', 1);
    APP_UTILITIES.setCookie('super_user_drilldown_to_account', 'true', 1);
    APP_UTILITIES.setCookie('user_role', JSON.stringify(userData), 1);
    APP_UTILITIES.setCookie('accountName', this.accountDt.accountName, 1);
    this.$router.push('/partnerhome');
    this.$emit('setIndicationData');
    this.$emit('loadNav', {});
  }

  async searchList() {
    /* istanbul ignore else */
    if (this.search) {
      await programListStore.searchProgramList(this.search);
      this.program_list = programListStore.searchdata.items;
      const items = programListStore.searchdata.total_items;
      this.total_items = items
        ? items
        : 0;
    }
  }

  addProgram() {
    this.$router.push('/accounts/createprogram');
  }


  @Watch('$route', { deep: true, immediate: true })
  checkForAccountId() {
    /* istanbul ignore else */
    if (this.$route.path.toLowerCase() === '/account/programs') {
      const accountId = this.userDetails.accountID;
      let id = this.getGlobalState.accountId;
      const storedAccountId: any = APP_UTILITIES.getCookie('accountId');
      const payload = APP_CONST.PROGRAM_PAYLOAD;
      payload.id = parseInt(storedAccountId);
      /* istanbul ignore else */
      if (payload != undefined) {
        id = payload.id;
      }
      /* istanbul ignore else */
      if (accountId || id) {
        this.accountById({ accountId: id });
      }
    }
  }

  beforeMount() {
    const dlObject = {
      'AccountName': undefined,
      'AccountType': undefined,
      'UserRole': 0,
      'ProgramName': undefined,
      'SiteName': undefined
    };
    this.$emit('updateDataLayer', dlObject);
    this.accountDt = {} as AccountDetails;
    const id = APP_UTILITIES.getCookie('accountId');
    /* istanbul ignore next */
    if (!this.userDetails.accountID && !id) {
      this.$router.push('/home');
    }
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : '';
    /* istanbul ignore next */
    if (this.authKey) {
      this.getSortingDefault();
    }
  }

  async accountById(payload: AccountDtById) {
    await programListStore.accountById(payload);
    /* istanbul ignore else */
    if (programListStore.accountDetails.status === APP_CONST.RESPONSE_200) {
      this.accountDt = { ...programListStore.accountDetails.data };
      this.accountAdminLength = this.accountDt.accountUsers.length;
      const dlObject = { 'AccountName': this.accountDt.accountName, 'AccountType': this.accountDt.type, 'UserRole': this.userDetails.roleId };
      this.$emit('updateDataLayer', dlObject);
    }
    else {
      this.accountDt = {} as AccountDetails;
    }
  }

  async updateProgramById(event: { programId: number; status: number }) {
    await programConfigureStore.UpdateProgram({ programId: event.programId, status: event.status });
    /* istanbul ignore else */
    if (programConfigureStore.UpdatedProgramRes.UpdatedProgramRes.status === APP_CONST.RESPONSE_200) {
      const temp_program_list = [...this.program_list];
      temp_program_list.find((el: any) => el.programId === event.programId).status = event.status;
      this.program_list = [...temp_program_list];
    }
  }

  backToAccounts() {
    this.$router.push('/home');
  }

  getAccountdminPic(type: string, base64: string) {
    let profileImageSrc = '';
    if (type !== '' || base64 !== '') {
      profileImageSrc = 'data:' + type + ';' + 'base64,' + base64;
    }
    return base64
      ? profileImageSrc
      : '';
  }

  editAccount(objEvent: any): void {
    this.$router.push('/accounts/edit');
  }

  emitToLoadNav() {
    this.$emit('indicationStrip');
  }

  getColorCode(email: string) {
    return APP_UTILITIES.getColorCode(email);
  }

  getInitialLater(firstName: string, lastName: string) {
    return firstName.trim().charAt(0).toUpperCase() + lastName.trim().charAt(0).toUpperCase();
  }

  acDetails() {
    this.showAcAdminDetails = true;
  }

  callHover(eventId: string, event: any, index: number, valFirst: string, valSecond: string) {
    /* istanbul ignore else */
    if (event.target.offsetWidth < event.target.scrollWidth) {
      this.hover = true;
      this.hoverText = eventId;
      this.hoverVal = valFirst + ' ' + valSecond;
      this.hoverIndex = index;
    }
    /* istanbul ignore else */
    if (eventId == 'name' || 'email') {
      this.calculateTopValueTooltip(eventId);
    }
  }

  calculateTopValueTooltip(eventName: string) {
    this.hoverStyleObj = {
      top: eventName == 'email'
        ? 30 + 'px'
        : 10 + 'px',
      left: 20 + 'px'
    };
  }
  callHoverOut() {
    this.hover = false;
    this.hoverText = '';
    this.hoverVal = '';
    this.hoverIndex = 0;
  }

  setSortingDefault(): void {
    const createKey = this.authKey + '_partner_program_sort';
    const sortOrder = (this.programPayload.sortDir == 1 || this.programPayload.sortDir == 0)
      ? '1'
      : '2';
    APP_UTILITIES.setSortingOnSession(createKey, this.programPayload.sortField, sortOrder);
  }

  getSortingDefault(): void {
    const createKey = this.authKey + '_partner_program_sort';
    let sortVal: string | null = '';
    if (APP_UTILITIES.getSortingOnSession(createKey)) {
      sortVal = APP_UTILITIES.getSortingOnSession(createKey);
      const sortObj: { key: string; dir: string } = sortVal
        ? JSON.parse(sortVal)
        : {};
      this.programPayload.sortField = sortObj.key;
      this.programPayload.sortDir = sortObj.dir;
      this.programList({ page: 1, sortField: this.programPayload.sortField, sortDir: this.programPayload.sortDir, type: '' });
      /* istanbul ignore else */
      if (this.programPayload.sortField) {
        this.program_header.forEach((element: any) => {
          if (element.value == this.programPayload.sortField) {
            element.order = parseInt(this.programPayload.sortDir);
          }
          else {
            element.order = 0;
          }
        });
      }
    }
    else {
      this.programList({ page: 1, sortField: '', sortDir: 1, type: '' });
      this.setSortingDefault();
    }
  }

  async resetProgram(programData: ProgramListDTO) {
    /* istanbul ignore if */
    if (programData.programId == (0 || null || undefined)) {
      return;
    }
    this.showLoader = true;
    const payload = { programId: programData.programId, programName: programData.programName, programStartDate: programData.startDate, programEndDate: programData.endDate, productId: programConfigureStore.productId };
    const response = await resetProgram(payload);
    /* istanbul ignore else */
    if (response.status == APP_CONST.RESPONSE_200) {
      APP_UTILITIES.showToastMessage('Program reset successfully', ToastType.Success);
      this.showLoader = false;
    }
    else {
      APP_UTILITIES.showToastMessage('Error while program reset', ToastType.Error);
      this.showLoader = false;
    }
  }
}
